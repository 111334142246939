<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :before-close="() => (dialogVisible = false)"
    :close-on-click-modal="false"
    width="60%"
    top="7vh"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>新增学员</span>
    </div>
    <el-form
      :model="formInfo"
      :rules="rules"
      :inline="false"
      ref="ruleForm"
      label-width="120px"
    >
      <el-row>
        <el-form-item label="姓名" prop="name" >
          <el-input v-model="formInfo.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select v-model="formInfo.sex" placeholder="请选择" style="width: 100%">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- <el-input v-model="formInfo.sex"></el-input> -->
        </el-form-item>
        <el-form-item label="手机号" prop="tel">
          <el-input v-model="formInfo.tel" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="初始密码" prop="pwd" >
          <el-input type="password" v-model="formInfo.pwd"  placeholder="请输入密码(密码必须包含大写字母、小写字母、数字、特殊字符中的三种)"></el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <el-row slot="footer">
      <el-button
        type="primary"
        @click="submitForm('ruleForm')"
        class="dialog-btn1"
      >
        确 定
      </el-button>
      <el-button @click="dialogVisible = false" type="info" class="dialog-btn">
        取 消
      </el-button>
    </el-row>
  </el-dialog>
</template>
<script>
import {merchantPasswordCheck} from '@/api/user'
export default {
  props: {
    title: { type: String, default: "" },
  },
  data() {
    const validatorPhone = function (rule, value, callback) {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      formInfo: {
        name: "",
        tel: "",
        pwd: "",
        state: "1",
        sex:''
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        sex:[{ required: true, message: "请选择性别", trigger: "blur" }],
        tel: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { required: true, validator: validatorPhone, trigger: "blur" },
        ],
        pwd: [{ required: true, message: "请输入初始密码(密码必须包含大写字母、小写字母、数字、特殊字符中的三种)", trigger: "blur" }],
      },
      options:[
        {
          value: '男',
          label: '男'
        }, 
        {
          value: '女',
          label: '女'
        }, 
      ]
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          // 密码校验
          const formdate = new FormData();
          formdate.append('password',  this.formInfo.pwd,)
          const res = await merchantPasswordCheck(formdate)
          if(!res.body){
            window.$msg('当前新密码不符合密码规范，请重新填写密码(密码必须包含大写字母、小写字母、数字、特殊字符中的三种)', 2)
            return
          }
          this.$emit("submit", {
            ...this.formInfo,
            loginName: this.formInfo.tel,
          });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    initData() {
      this.resetForm("ruleForm");
      this.formInfo = {
        sex:'',
        name: "",
        tel: "",
        pwd: "",
        state: "1",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/dialog.scss";
.el-form-item .el-form-item {
  margin-bottom: 22px;
}
/deep/ .el-dialog__body {
  padding-top: 0px;
}
.dialog-btn1 {
  width: 200px;
  height: 50px;
  margin-bottom: 38px;
  &:first-child {
    margin-left: 220px;
    margin-right: 38px;
  }
}
</style>